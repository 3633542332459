import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Divider, notification, Popconfirm, Table, Tag } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { useUsers } from "../../../hooks/UserHooks";

const { Column } = Table;

/**
 * The user management table
 */
const UserManagementTable = ({ data, loading }) => {
  const { deleteUser } = useUsers();

  const history = useHistory();

  // Go to the edit user page
  const onEditIconClick = (id) => {
    history.push(`/edit-user/${id}`);
  };

  // Handle delete user
  const onDeleteRecord = (id, username) => {
    deleteUser(id).then(() => {
      notification.success({
        message: `Deleted ${username} successfully!`,
        description: ``,
        placement: "topRight"
      });
    });
  };

  return (
    <>
      <Table dataSource={data} rowKey="username" loading={loading}>
        <Column 
          title="First Name" 
          dataIndex="firstName"
          sorter={(a, b) => {
            const nameA = a.firstName || ""; // If a.firstName is null, use an empty string
            const nameB = b.firstName || ""; // If b.firstName is null, use an empty string
            return nameA.localeCompare(nameB);
          }}
        />
        <Column 
          title="Last Name" 
          dataIndex="lastName" 
          sorter={(a, b) => {
            const nameA = a.lastName || ""; 
            const nameB = b.lastName || ""; 
            return nameA.localeCompare(nameB);
          }}
        />
        <Column
          title="Email Address"
          dataIndex="email"
          sorter={(a, b) => a.email.localeCompare(b.email)}
          render={(email) => (
            <>
              {
                <div className="character-limit">{email}</div>
              }
            </>
          )}
        />
        <Column
          title="OEM/Sites"
          dataIndex="userGroups"
          render={(userGroups) => (
            <>
              {userGroups &&
                userGroups.map((userGroup) => (
                  <Tag key={userGroup} color="green">
                    {userGroup}
                  </Tag>
                ))}
            </>
          )}
        />
        <Column
          title="User Authorization"
          dataIndex="userRoles"
          render={(userRoles) => (
            <>
              {userRoles &&
                userRoles.map((userRole) => (
                  <Tag key={userRole} color="green">
                    {userRole == 'x-admin(ADMIN)' ? 'xAdmin(ADMIN)':'xAssist(TECHNICIAN)'}
                  </Tag>
                ))}
            </>
          )}
        />
        <Column
          title="Action"
          width="10%"
          render={({ id, username }) => {
            return (
              <>
                <EditOutlined style={{ fontSize: "20px" }} onClick={() => onEditIconClick(id)} />
                <Divider type="vertical" style={{ display: "hidden" }} />
                <Popconfirm
                  placement="bottomLeft"
                  title="Do you really want to delete this user?"
                  onConfirm={() => {
                    onDeleteRecord(id, username);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>
                    <DeleteOutlined style={{ fontSize: "20px" }} />
                  </a>
                </Popconfirm>
              </>
            );
          }}
        />
      </Table>
    </>
  );
};

export default UserManagementTable;
