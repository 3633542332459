import { LogoutOutlined, ProfileOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Col, Dropdown, Image, Input, Layout, Menu, message, Row, Select } from "antd";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import LexxLogo from "../../../assets/images/lexx-logo.png";
import { useTopMenuNavigationStyles } from "./styles";
import KeycloakUserService from "../../../services/KeycloakUserService";

const { Header } = Layout;
const { Option } = Select;

/**
 * The top menu navigation bar
 */
const TopMenuNavigation = () => {
  const groups = KeycloakUserService.getGroups();
  let group = localStorage.getItem("projectGroup") !== null ? localStorage.getItem("projectGroup") : groups[0];
  localStorage.setItem("projectGroup", group);
  const [selectedValue, setSelectedValue] = useState(group);
  let projectMapping = {
    '/EDPRL' : 'Lone Star',
    '/EDPRPQ' : 'Prairie Queen',
    '/EDPRV' : 'Vestas',
    '/EDPRS' : 'Suzlon',
    '/EDPRG' : 'G114'
  }

  const history = useHistory();
  const { headerCls, searchFieldCls, avatarCls, menuItemCls, menuItemIconCls, dropDownClass } =
    useTopMenuNavigationStyles();
  const [profilePhotoUrl, setProfilePhotoUrl] = useState("");

  // Get the user profile photo and display it
  useEffect(() => {
    const profilePhotoUrlFromKeycloak = KeycloakUserService.getProfilePhotoUrl();
    setProfilePhotoUrl(profilePhotoUrlFromKeycloak);
  });

  // Go to the root page
  const onLogoClick = () => {
    history.push("/");
  };

  // Go to the profile page
  const onProfileItemClick = () => {
    history.push("/profile");
  };

  // Logout the user
  const onLogoutItemClick = () => {
    KeycloakUserService.doLogout();
  };

  const handleSelectChange = (value) => {
    setSelectedValue(value);
    localStorage.setItem("projectGroup", value);
    // Reload the entire page
    window.location.reload();
  };

  const menu = (
    <Menu>
      <Menu.Item
        className={menuItemCls}
        key="1"
        icon={<ProfileOutlined className={menuItemIconCls} />}
        onClick={onProfileItemClick}
      >
        Profile
      </Menu.Item>
      <Menu.Item
        className={menuItemCls}
        key="3"
        icon={<LogoutOutlined className={menuItemIconCls} />}
        onClick={onLogoutItemClick}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className={headerCls}>
      <Row align="middle" justify="space-between" style={{ marginBottom: "50%" }}>
        <Col span={16}>
          <Row align="middle">
            <Image width={120} src={LexxLogo} preview={false} onClick={onLogoClick} style={{ marginBottom: "15%" }} />
          </Row>
        </Col>
        <Col>
          <p style={{ color: "white" }}>Select OEM/Site</p>
        </Col>
        <Col>
          <Select value={selectedValue} onChange={handleSelectChange} className={dropDownClass}>
            {groups.map((item, i) => {
              return <Option value={item}>{projectMapping[item]}</Option>;
            })}
          </Select>
        </Col>
        <Col>
          <span>
            <Dropdown overlay={menu} trigger={["click"]}>
              {profilePhotoUrl ? (
                <Avatar className={avatarCls} size="large" src={profilePhotoUrl} crossOrigin="" />
              ) : (
                <Avatar className={avatarCls} size="large" icon={<UserOutlined />} />
              )}
            </Dropdown>
          </span>
        </Col>
      </Row>
    </Header>
  );
};

export default TopMenuNavigation;
