import { CloseCircleOutlined, SearchOutlined, UserAddOutlined } from "@ant-design/icons";
import { Button, Input, Row, Space, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useBoolean, useDebounce } from "usehooks-ts";
import AddNewMemberModal from "../AddNewMemberModal/AddNewMemberModal";
import { useMemberManagerToolbarStyles } from "./styles";

/**
 * The user management toolbar
 */
export const UserManagementToolbar = ({ onSearch }) => {
  const [query, setQuery] = useState(null);
  const debouncedQuery = useDebounce(query, 500);
  const { value: modalVisible, setTrue: showModal, setFalse: hideModal } = useBoolean();

  useEffect(() => {
    if (debouncedQuery !== null) {
      onSearch(debouncedQuery);
    }
  }, [debouncedQuery]);

  const { menuBarCls } = useMemberManagerToolbarStyles();

  return (
    <Row className={menuBarCls} justify="space-between">
      <Space>
        <Button icon={<UserAddOutlined />} type="link" onClick={showModal}>
          Add users
        </Button>
      </Space>
      <Space>
        {query && (
          <CloseCircleOutlined
            onClick={() => {
              setQuery("");
            }}
          />
        )}
        <Tooltip title={'Search user by First Name, Last Name, Email or Username'}>
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search users"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </Tooltip>
      </Space>
      <AddNewMemberModal visible={modalVisible} onCancel={hideModal} />
    </Row>
  );
};
