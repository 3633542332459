import { Divider, Form, Layout, Row, Typography } from "antd";
import React ,{ useEffect } from "react";
import { useUsers } from "../../../hooks/UserHooks";
import SideNavigationMenu from "../../organisms/SideNavigationMenu/SideNavigationMenu";
import TopMenuNavigation from "../../organisms/TopMenuNavigation/TopMenuNavigation";
import UserManagementTable from "../../organisms/UserManagementTable/UserManagementTable";
import { UserManagementToolbar } from "../../organisms/UserManagementToolbar/UserManagementToolbar";
import { useTeamManagementPageStyles } from "./styles";

const { Content } = Layout;
const { Text } = Typography;

/**
 * The user management page
 */
const UserManagementPage = () => {
  const { data, loading, searching, searchUsers } = useUsers();

  const { mainContentCls, titleCls } = useTeamManagementPageStyles();

  // Search users
  const onSearch = (q) => {
    q = q.toLowerCase();
    searchUsers(q);
  };
  useEffect(() => {
    searchUsers('');
  }, []);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <TopMenuNavigation />
      <Layout>
        <SideNavigationMenu />
        <Content className={mainContentCls}>
          <Form name="control-ref" layout="vertical">
            <Row align="middle" justify="space-between">
              <Text className={titleCls}>Users</Text>
            </Row>
            <Divider />
            <UserManagementToolbar onSearch={onSearch} />
            <UserManagementTable data={data} loading={loading || searching} />
          </Form>
        </Content>
      </Layout>
    </Layout>
  );
};

export default UserManagementPage;
