import { Button, Form, Input, message, Modal, notification, Select, Space } from "antd";
import { ClientError } from "graphql-request";
import React from "react";
import { LexxApplication } from "../../../consts/LexxApplication";
import { RealmRole } from "../../../consts/RealmRole";
import { useUsers } from "../../../hooks/UserHooks";
import { useClientWithRoles } from "../../../hooks/ClientHooks";
import { XAdminRole } from "../../../consts/XAdminRole";
import { XTeamRole } from "../../../consts/XTeamRole";

const { Option } = Select;

/**
 * The add new user pop up form
 */
const AddNewMemberModal = ({ visible, onCancel }) => {
  const { createUser, searchUsers } = useUsers();
  const { data: xteamClientWithRoles } = useClientWithRoles(LexxApplication.XTEAM);
  const { data: xadminClientWithRoles } = useClientWithRoles(LexxApplication.XADMIN);

  // Find client role id using role name
  const findRolesID = (clientWithRoles, clientRole) => {
    for (let i = 0; i < clientWithRoles.keycloakRoles.length; i++) {
      if (clientWithRoles.keycloakRoles[i]["name"] == clientRole) {
        return clientWithRoles.keycloakRoles[i]["id"];
      }
    }
    return "";
  };

  // On form submit button clicked
  const onFinish = ({ username, firstName, lastName, email, password, lexxApplication }) => {
    let availableApps;
    let clientIDs;
    let clientRolesID;
    let clientRolesName;

    // To retrieve the realm roles and the client roles
    switch (lexxApplication) {
      // Get the client roles information that is required when creating a user
      case LexxApplication.XTEAM:
        // Get Realm role
        availableApps = [RealmRole.XTEAM];
        // Get client id
        clientIDs = [xteamClientWithRoles.id];
        // Get client role id
        clientRolesID = [findRolesID(xteamClientWithRoles, XTeamRole.LEAD_TECHNICIAN)];
        // Get client role name
        clientRolesName = [XTeamRole.LEAD_TECHNICIAN];
        break;
      case LexxApplication.XADMIN:
        // Get Realm role
        availableApps = [RealmRole.XADMIN];
        // Get client id
        clientIDs = [xadminClientWithRoles.id];
        // Get client role id
        clientRolesID = [findRolesID(xadminClientWithRoles, XAdminRole.ADMIN)];
        // Get client role name
        clientRolesName = [XAdminRole.ADMIN];
        break;
      case LexxApplication.ALL:
        // Get Realm role
        availableApps = [RealmRole.XTEAM, RealmRole.XADMIN];
        // Get client id
        clientIDs = [xteamClientWithRoles.id, xadminClientWithRoles.id];
        // Get client role id
        clientRolesID = [];
        clientRolesID.push(findRolesID(xteamClientWithRoles, XTeamRole.LEAD_TECHNICIAN));
        clientRolesID.push(findRolesID(xadminClientWithRoles, XAdminRole.ADMIN));
        // Get client role name
        clientRolesName = [XTeamRole.LEAD_TECHNICIAN, XAdminRole.ADMIN];
        break;
      default:
        clientIDs = [];
        clientRolesID = [];
        clientRolesName = [];
    }

    // Call the backend to create a user
    createUser({
      username,
      firstName,
      lastName,
      email,
      password,
      availableApps,
      clientIDs,
      clientRolesID,
      clientRolesName
    })
      .then(() => {
        notification.success({
          message: "Created new user successfully!",
          placement: "topRight"
        });
        onCancel();
        searchUsers("");
      })
      .catch((e) => {
        if (e instanceof ClientError) {
          e.response.errors.forEach((error) => {
            message.error(error.message).then();
          });
        }
      });
  };

  const validateUsername = (rule, value, callback) => {
    if (/\s/.test(value)) {
      callback('Username cannot contain spaces.');
    } else {
      callback();
    }
  };

  return (
    <Modal title="Add a new user" visible={visible} footer={null} closable={false} onCancel={onCancel}>
      <Form layout="vertical" name="basic" onFinish={onFinish}>
      <Form.Item label="Username" name="username" rules={[
          { required: true, message: "Please input username!" },
          { validator: validateUsername }]}>
          <Input disabled={false}/>
        </Form.Item>
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[{ required: true, message: "Please input first name!" }]}
        >
          <Input disabled={false}/>
        </Form.Item>
        <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: "Please input last name!" }]}>
          <Input disabled={false}/>
        </Form.Item>
        <Form.Item label="Email" name="email" rules={[{
              type: 'email',
              message: 'Please input a valid email address!',
            },{ required: true, message: "Please input email address!" }]}>
          <Input disabled={false}/>
        </Form.Item>
        <Form.Item label="Password" name="password" 
        rules={[
          { required: true, message: "Please input password!" },
          {
            pattern: /\d/,
            message: "✕ Must have at least one number"
          },
          {
            pattern: /.*[A-Z].*/,
            message: "✕ Must have at least one capital letter"
          },
          () => ({
            validator(_, value) {
              if (!value || value.length >= 8) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("✕ Must have at least 8 characters"));
            }
          })
        ]}>
          <Input.Password disabled={false}/>
        </Form.Item>
        <Form.Item
          label="User Authorization"
          name="lexxApplication"
          rules={[{ required: true, message: "Please select lexx application" }]}
        >
          <Select>
            <Option value={LexxApplication.XADMIN}>xAdmin default roles</Option>
            <Option value={LexxApplication.XTEAM}>xAssist default roles</Option>
            {/* <Option value={LexxApplication.ALL}>ALL</Option> */}
          </Select>
        </Form.Item>
        <Form.Item style={{ textAlign: "right" }}>
          <Space>
            <Button type="default" onClick={onCancel}>
              Close
            </Button>
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddNewMemberModal;
