import { FileOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { Layout, Menu, Row } from "antd";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSideNavigationMenuStyles } from "./styles";
import { useUsers } from "../../../hooks/UserHooks";

const { Sider } = Layout;

/**
 * The side nagivation menue
 */
const SideNavigationMenu = () => {
  const history = useHistory();
  const location = useLocation();
  const { searchUsers } = useUsers();

  // Handle switching to different pages
  const onClick = ({ key }) => {
    if (key.startsWith("/")) {
      searchUsers("");
      history.push(key);
    }
  };

  const { sideBarContainerCls } = useSideNavigationMenuStyles();

  return (
    <Sider width={300} className={sideBarContainerCls}>
      <Row>
        <Menu defaultSelectedKeys={[location.pathname]} mode="inline" onClick={onClick}>
          <Menu.Item icon={<FileOutlined />} key="/usage-reports">
            Usage Reports
          </Menu.Item>
          <Menu.Item icon={<TeamOutlined />} key="/user-management">
            User Management
          </Menu.Item>
          <Menu.Item icon={<UserOutlined />} key="/profile">
            My Profile
          </Menu.Item>
        </Menu>
      </Row>
    </Sider>
  );
};

export default SideNavigationMenu;
